<template>
  <v-container fluid>
    <ApolloQuery
      :query="require('@/graphql/user/allUsers.gql')"
      :variables="{ client_id: client_id, garbage: rando }"
    >
      <template v-slot="{ result: { loading, error, data } }">
        <v-progress-linear indeterminate v-if="loading" />
        <v-alert type="warning" v-if="hasUpdatedProjectNames" dismissible>
          Due to the user deletion, you now own and/or manage the following
          projects: <br />
          <strong>{{ updatedProjectNames }}</strong>
        </v-alert>
        <v-data-table
          v-if="data && !loading"
          v-model="selected"
          :items="data.allUsers"
          :headers="headers"
          item-key="id"
          :search="keywords"
          :items-per-page.sync="itemsPerPage"
          show-select
          :footer-props="{ itemsPerPageOptions }"
        >
          <template v-slot:item.user_type="{ item }">
            {{ mapUserType(item.user_type) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon class="mr-2" @click="editUser(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="promptForDelete(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <v-alert type="error" v-else-if="error">
          There was an error loading data. The server said...<br />
          <strong>{{ error }}</strong>
        </v-alert>
      </template>
    </ApolloQuery>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="320"
      v-if="selectedUser.name != ''"
    >
      <v-card>
        <v-card-title class="headline">Delete User?</v-card-title>
        <v-card-text>
          <p>
            You are about to delete the user
            <strong>{{ selectedUser.name }}</strong
            >. This cannot be undone. Are you sure you want to delete this user?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteDialog = false">{{
            $t("cancel")
          }}</v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="deleteUser(selectedUser)"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "UsersGrid",
  props: {
    keywords: {
      type: String,
      required: true
    },
    reload: {
      type: Number,
      default: 0
    },
    client_id: {
      type: String
    }
  },
  data: () => ({
    itemsPerPageOptions: [25, 50, 100],
    itemsPerPage: 25,
    deleteDialog: false,
    hasUpdatedProjectNames: false,
    updatedProjectNames: "",
    selectedUser: {},
    selected: [],
    rando: 0,
    headers: [
      {
        text: "Name",
        align: "left",
        value: "name"
      },
      { text: "Email", value: "email" },
      { text: "Type", value: "user_type" },
      { text: "Client", value: "client.name" },
      { text: "Actions", align: "right", sortable: false, value: "action" }
    ]
  }),
  watch: {
    reload: function(val) {
      this.rando = val;
    }
  },
  methods: {
    mapUserType(type) {
      if (type === "admin") {
        return "Administrator";
      } else if (type === "client_admin") {
        return "Company Administrator";
      } else {
        return "Company User";
      }
    },
    promptForDelete(user) {
      this.selectedUser = user;
      this.deleteDialog = true;
    },

    async retrieveProjectsForUserDeletion(user) {
      // Retrieve user owned projects
      const userOwnedProjectsData = await this.$apollo.query({
        query: require("@/graphql/project/allProjectsOwned.gql"),
        variables: {
          owner_id: user.id,
        },
      });
      const userOwnedProjects =
        userOwnedProjectsData.data.allProjectsOwned || [];

      // Retrieve user managed projects
      const userManagedProjectsData = await this.$apollo.query({
        query: require("@/graphql/project/allProjectsManaged.gql"),
        variables: {
          manager_id: user.id,
        },
      });
      const userManagedProjects = userManagedProjectsData.data.allProjectsManaged || [];
      return userOwnedProjects.concat(userManagedProjects);
    },

    async retrieveUserForDeletion() {
      const userData = await this.$apollo.query({
        query: require("@/graphql/user/me.gql"),
      });
      const currentUser = userData.data.me;
      return currentUser;
    },

    async updateProjectsForUserDeletion(user) {
      const previousUserAssignedProjects = await this.retrieveProjectsForUserDeletion(
        user
      );
      const currentUser = await this.retrieveUserForDeletion();

      // Update owned and managed projects
      // By re-assigning user to be deleted with current user.
      const promises = previousUserAssignedProjects.map(async eachProject => {
        await this.$apollo.mutate({
          mutation: require("@/graphql/project/updateProject.gql"),
          variables: {
            id: eachProject.id,
            name: eachProject.name,
            description: eachProject.description,
            manager:
              eachProject.manager.id == user.id
                ? currentUser.id
                : eachProject.manager.id,
            client: eachProject.client.id,
            owner:
              eachProject.owner.id == user.id
                ? currentUser.id
                : eachProject.owner.id,
          },
        });
      });
      await Promise.all(promises);
      const updatedProjectNames =
        previousUserAssignedProjects.length == 0
          ? null
          : previousUserAssignedProjects
              .map((eachProject) => eachProject.name)
              .join(", ");
      return updatedProjectNames;
    },

    async deleteUser(user) {
      const updatedProjectNames = await this.updateProjectsForUserDeletion(
        user
      );
      this.$apollo
        .mutate({
          // Query
          mutation: require("@/graphql/user/deleteUser.gql"),
          // Parameters
          variables: {
            id: user.id,
          },
        })
        .then((data) => {
          // report success
          this.$emit("user-form-closed", data);
          this.rando++;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          // Error
          // update user....
        });
      this.deleteDialog = false;
      this.hasUpdatedProjectNames = updatedProjectNames && updatedProjectNames.length > 0;
      this.updatedProjectNames = updatedProjectNames;
    },
    editUser(user) {
      this.$emit("edit-user", user);
    }
  }
};
</script>
